
import { Component, Vue } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import Confirm from '@/components/core/Confirm.vue';
import moment from 'moment';
import DateTimeUtils from '@/utils/date-time-utils';
import { namespace } from 'vuex-class';
import NotesTodoServices from '@/services/notes-todo-services';
import ld from 'lodash';
import NotesTodoMixin from '@/mixins/notes-todo-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import { tableWidths } from '@/constants/index';

const settings = namespace('settings');
const notestodo = namespace('notestodo');
const classes = namespace('classes');
const students = namespace('students');
const plans = namespace('plans');

@Component({
  mixins: [PageLifeCycleMixin, NotesTodoMixin, TableResizeMixin]
})
export default class NotesTodo extends Vue {
  $refs!: {
    reminders: Confirm;
  };

  headers = [
    { text: this.$t('dateLabel'), value: 'reminderDate', width: this.tableWidths.fullDate },
    { text: this.$t('typeLabel'), value: 'reminderType', width: this.tableWidths.shortText },
    {
      text: this.$t('reminderTextLabel'),
      align: 'start',
      value: 'reminderTextEscaped',
      class: 'header-longText'
    },
    { text: this.$t('classLabel'), value: 'subject', width: this.tableWidths.mediumText },
    { text: this.$t('studentLabel'), value: 'student', width: this.tableWidths.mediumText },
    {
      width: this.tableWidths.action2,
      sortable: false,
      value: 'action'
    }
  ];

  localTodo:any = null;

localNote: {
    yearId: any;
    subjectId: any;
    studentId: any;
    noteDate: any;
    noteText: any;
    noteId?: any;
  } = {
    yearId: 0,
    subjectId: 0,
    studentId: 0,
    noteDate: '',
    noteText: '',
    noteId: null
  };

  isQuickRename = false;
  clickQuickRenameKey:any = null;
  newQuickReminderText: any = null;
  clickQuickReminderType:any = null;
  errorNewQuickReminder = false;
  quickEditingType:any = '';
  isEditingQuickField = false;

  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;
  tableHeight!: string;

  @settings.Getter('getNotesTodosStyling')
  notesTodosStyling!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @notestodo.State('todosList')
  mytodosData!: any;

  @notestodo.State('notesList')
  mynotesData!: any;

  @notestodo.Mutation('setTodos')
  setTodos!: any;

  @notestodo.Mutation('setNotes')
  setNotes!: any;

  @notestodo.Mutation('setHaveTodos')
  setHaveTodos!: any;

  @notestodo.Mutation('setHaveNotes')
  setHaveNotes!: any;

  @notestodo.Action
  loadTodos!: () => Promise<any>;

  @notestodo.Action
  loadNotes!: (params?: any) => Promise<any>;

  @notestodo.Action
  addTodo!: (params?: any) => Promise<any>;

  @notestodo.Action
  updateTodo!: (params?: any) => Promise<any>;

  @notestodo.Action
  deleteMultipleNotesTodos!: (params?: any) => Promise<any>;

  @notestodo.Action
  deleteNote!: (params?: any) => Promise<any>;

  @notestodo.Action
  addOrUpdateNote!: (params?: any) => Promise<any>;

  @notestodo.Getter
  getListLoading!: boolean;

  @classes.Getter('getClassItems')
  classItems!: Array<any>;

  @classes.Getter
  getClassName!: (param?: string | number) => string;

  @students.Getter('getNotesStudentsItems')
  studentItems!: Array<any>;

  @students.Action
  loadNotesStudents!: (params?: any) => Promise<any>;

  @plans.Action
  reloadPlans!: (param?: any) => Promise<any>;

  @plans.Mutation
  setResetOnly!: (resetOnly: boolean) => void;

  get hasText() {
    return CommonUtils.hasText;
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get tableWidths() {
    return tableWidths;
  }

  get todos() {
    if (Array.isArray(this.mytodosData)) {
      return this.mytodosData || [];
    } else {
      return [];
    }
  }

  get notes() {
    if (Array.isArray(this.mynotesData)) {
      return this.mynotesData || [];
    } else {
      return [];
    }
  }

  get notesTodo():Array<any> {
    const notes = this.notes;
    const todos = this.todos;
    let items: Array<any> = [];
    items = items.concat(todos.map((todo: any) => {
      todo.reminderText = todo.toDoText;
      todo.reminderTextEscaped = this.htmlEscape(todo.toDoText);
      todo.reminderDate = todo.currentDate || todo.dueDate || todo.startDate;
      todo.reminderType = 'TD';
      todo.reminderId = todo.toDoId;
      todo.reminderKey = todo.toDoId + todo.currentDate || todo.dueDate;
      todo.updateCurrentTodo = false;
      todo.subject = '';
      todo.student = '';
      if (CommonUtils.isNotEmpty(todo.customTodo)) {
        for (const c of todo.customTodo) {
          if (c.currentDate === todo.currentDate) {
            todo.reminderText = c.toDoText;
            todo.reminderTextEscaped = this.htmlEscape(c.toDoText);
            todo.reminderDate = c.currentDate;
            todo.customTodoId = c.customTodoId;
            todo.customDone = c.done;
            todo.customPriority = c.priority;
            todo.customToDoText = c.toDoText;
          }
        }
        return todo;
      } else {
        return todo;
      }
    }).filter((todo:any) => {
      if (CommonUtils.hasText(todo.repeats) && CommonUtils.hasText(todo.startDate) && CommonUtils.hasText(todo.dueDate)) {
        if (DateTimeUtils.isBetween(DateTimeUtils.currentDateAddDays(0), todo.startDate, todo.dueDate)) {
          if (todo.repeats === 'weekly') {
            return this.isDateWithinCurrentWeek(todo.currentDate)
          } else {
            return (todo.currentDate === DateTimeUtils.currentDateAddDays(0));
          }
        } else {
          return (todo.currentDate === todo.startDate);
        }
      }
      return true;
    })
    );
    items = items.concat(notes.map((note: any) => {
      note.reminderText = note.noteText;
      note.reminderTextEscaped = this.htmlEscape(note.noteText);
      note.reminderDate = note.noteDate === '9999-12-31' ? '' : moment(note.noteDate).format('MM/DD/YYYY');
      note.reminderType = 'NT';
      note.reminderId = note.noteId;
      note.reminderKey = note.noteId + moment(note.noteDate).format('MM/DD/YYYY');
      note.subject = note.subjectId ? this.className(note.subjectId) : '';
      note.student = note.studentId ? this.studentName(note.studentId) : '';
      return note;
    })
    );
    return items.sort();
  }

  get localListLoading() {
    return this.getListLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('notestodo/setListLoading', value);
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  getReminderTitle(item: any) {
    if (item.reminderType === 'TD') {
      return this.$t('todoNameLabel', { name: item.reminderText });
    } else {
      return this.$t('notesNameLabel', { name: item.reminderText });
    }
  }

  className(subjectId: number) {
    const subject = this.classItems.find((c) => c.value === subjectId)
    return subject ? subject.text : '';
  }

  studentName(studentId: number) {
    if (this.studentItems.length === 1) {
      return '';
    }
    const student = this.studentItems.find((s) => +s.value === +studentId);
    return student ? student.text : '';
  }

  isDateWithinCurrentWeek(date:any) {
    return moment(date, 'MM/DD/YYYY').isSameOrBefore(moment().endOf('week')) && moment(date, 'MM/DD/YYYY').isSameOrAfter(moment().startOf('week'))
  }

  isDateIsPast(date:any) {
    return moment(date, 'MM/DD/YYYY').isBefore(moment());
  }

  isTrueForTodo(item:any):boolean {
    if (item.customTodoId === undefined) {
      return item.done;
    } else {
      return item.customDone;
    }
  }

  customSort(items:any, index:any, isDesc:any) {
    items.sort((a:any, b:any) => {
      if (index[0] === 'reminderDate') {
        if (!isDesc[0]) {
          const leftVal:any = new Date(b[index]);
          const rightVal:any = new Date(a[index])
          return leftVal - rightVal;
        } else {
          const leftVal:any = new Date(a[index]);
          const rightVal:any = new Date(b[index]);
          return leftVal - rightVal;
        }
      } else {
        if (typeof a[index] !== 'undefined') {
          if (!isDesc[0]) {
            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
          } else {
            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
          }
        }
      }
    });
    return items;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  rowClicked(text: any, type: any, itemJSON: any) {
    this.isQuickRename = true;
    if (!this.isEditingQuickField) {
      this.newQuickReminderText = this.htmlEscape(text);
    }
    this.clickQuickReminderType = type;
    if (type === 'TD') {
      this.clickQuickRenameKey = itemJSON.toDoId + itemJSON.currentDate || itemJSON.dueDate;
      this.localTodo = itemJSON;
      this.quickEditingType = 'TD';
    } else if (type === 'NT') {
      this.clickQuickRenameKey = itemJSON.noteId + moment(itemJSON.noteDate).format('MM/DD/YYYY');
      this.localNote.subjectId = itemJSON.subjectId;
      this.localNote.studentId = itemJSON.studentId;
      this.localNote.yearId = itemJSON.yearId;
      this.localNote.noteDate = itemJSON.noteDate;
      this.localNote.noteId = itemJSON.noteId;
      this.quickEditingType = 'NT';
    }
    this.isEditingQuickField = true;
  }

  get htmlEscape() {
    return CommonUtils.htmlEscape;
  }

  quickRenameBlur() {
    this.isQuickRename = false;
    this.isEditingQuickField = false;
    this.errorNewQuickReminder = false;
    this.clickQuickReminderType = null;
    this.clickQuickRenameKey = null;
    if (this.localTodo && !CommonUtils.hasText(this.localTodo.currentDate)) {
      this.newQuickReminderText = null;
      this.localTodo = null;
    }
    CommonUtils.hideLoading();
    this.localListLoading = false;
  }

  quickRenameSave(item:any) {
    const that = this;
    if (this.newQuickReminderText === '') {
      this.errorNewQuickReminder = true;
    } else {
      this.errorNewQuickReminder = false;
      const sanitizedInput = CommonUtils.sanitizeInput(that.newQuickReminderText);
      if (this.quickEditingType === 'TD') {
        that.localTodo.done = CommonUtils.isTrue(that.localTodo.done) ? 1 : 0;
        that.localTodo.customTodo = JSON.stringify(that.localTodo.customTodo);
        // if repeating todo
        if (CommonUtils.hasText(that.localTodo.currentDate)) {
          that.$refs.reminders.confirm({
            title: that.$t('editToDoDialogTitle'),
            text: that.$t('editTodoMsg2', { text: DateTimeUtils.formatToShow(that.localTodo.currentDate) }),
            option1ButtonAlternativeText: that.$t('allTodosLabel'),
            option2ButtonAlternativeText: that.$t('onlyThisTodoLabel')
          }).then((result:number) => {
            if (result === 1) {
              that.localTodo.updateCurrentTodo = false;
              that.localTodo.toDoText = sanitizedInput;
              that.localListLoading = true;
              that.updateTodo(that.localTodo).then(that.refresh);
            } else if (result === 2) {
              that.localTodo.updateCurrentTodo = true;
              that.localTodo.customToDoText = sanitizedInput;
              const initialTodoData = ld.cloneDeep(that.localTodo);
              // adding new custom todo
              if (that.localTodo.customTodoId === undefined && CommonUtils.isNotEmpty(JSON.parse(that.localTodo.customTodo || '[]'))) {
                that.localTodo.toDoText = sanitizedInput;
                NotesTodoServices.createNewCustomTodo(that.localTodo, initialTodoData);
              }
              that.localListLoading = true;
              that.updateTodo(that.localTodo).then(() => {
                if (this.showSnackbarNotifications) {
                  this.$snotify.success(this.$t('statusMsg66') as string);
                }
                that.refresh()
              });
            } else {
              return Promise.resolve();
            }
          });
        } else {
          this.localTodo.toDoText = sanitizedInput;
          that.localListLoading = true;
          this.updateTodo(this.localTodo).then(() => {
            item.toDoText = sanitizedInput;
            that.quickRenameBlur();
          });
        }
      } else if (this.quickEditingType === 'NT') {
        const sanitizedInput = CommonUtils.sanitizeInput(that.newQuickReminderText);
        this.localNote.noteText = sanitizedInput;
        that.localListLoading = true;
        this.addOrUpdateNote(this.localNote).then(() => {
          if (this.showSnackbarNotifications) {
            that.$snotify.success(this.$t('statusMsg67') as string);
          }
          item.noteText = sanitizedInput;
          that.quickRenameBlur();
        });
      }
    }
  }

  deleteReminder(item: any) {
    const that = this;
    if (item.reminderType === 'TD') {
      if (item.customTodoId !== undefined) {
        this.$refs.reminders.confirm({
          title: this.$t('deleteTodoLabel'),
          text: this.$t('wouldYouLikeToDeleteTodoMsg', { text: DateTimeUtils.formatToShow(item.currentDate) }),
          option1ButtonAlternativeText: this.$t('allTodosLabel'),
          option2ButtonAlternativeText: this.$t('onlyThisTodoLabel')
        }).then((result) => {
          if (result === 1) {
            // all todos
            that.localListLoading = true;
            that.updateTodo({ toDoId: item.reminderId, action: 'D' }).then(this.refresh);
            return Promise.resolve();
          } else if (result === 2) {
            // current only
            that.localListLoading = true;
            that.updateTodo({ toDoId: item.reminderId, customTodoId: item.customTodoId, customTodo: JSON.stringify(item.customTodo), action: 'D' }).then(this.refresh);
            return Promise.resolve();
          } else {
            return Promise.resolve();
          }
        })
      } else {
        this.$refs.reminders
          .confirm({
            title: this.$t('todoEvent'),
            text: this.$t('todoDeleteText'),
            option1ButtonAlternativeText: this.$t('continueLabel')
          })
          .then((result) => {
            if (result === 1) {
              that.localListLoading = true;
              that.updateTodo({ toDoId: item.reminderId, action: 'D' }).then(() => {
                this.$eventBus.$emit('deleteReminders', [{ type: 'TD', id: item.reminderId }]);
                if (this.showSnackbarNotifications) {
                  this.$snotify.success(this.$t('statusMsg68') as string);
                }
                this.refresh()
              });
            }
          });
      }
    } else if (item.reminderType === 'NT') {
      const that = this;
      this.$refs.reminders
        .confirm({
          title: this.$t('notesEvent'),
          text: this.$t('noteDeleteText'),
          option1ButtonAlternativeText: this.$t('continueLabel')
        })
        .then((result) => {
          if (result === 1) {
            that.localListLoading = true;
            that.deleteNote({ noteId: item.reminderId }).then(() => {
              this.$eventBus.$emit('deleteReminders', [{ type: 'NT', id: item.reminderId }]);
              if (this.showSnackbarNotifications) {
                this.$snotify.success(this.$t('statusMsg69') as string);
              }
              this.refresh()
            });
          }
        });
    }
  }

  multipleDelete() {
    const that = this;
    this.$refs.reminders
      .confirm({
        title: this.$t('remindersLabel'),
        text: this.$t('deleteMultipleRemindersText'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      })
      .then((result) => {
        if (result === 1) {
          that.localListLoading = true;
          const toDelete = that.selectedRows.map((r: any) => { return { type: r.reminderType, id: r.reminderId } });
          that.deleteMultipleNotesTodos(that.selectedRows).then(() => {
            this.$eventBus.$emit('deleteReminders', toDelete);
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg70') as string);
            }
            that.selectedRows = [];
            that.refresh();
          });
        }
      });
  }

  addReminder(addWhat: string) {
    this.$eventBus.$emit('openSubPage', {
      type: 'notesTodoEditor',
      modal: this.$currentUser.defaultEditorMode === 'modal',
      width: 600,
      input: { addWhat: addWhat, editWhat: null }
    });
  }

  editReminder(reminderData: any) {
    this.$eventBus.$emit('openSubPage', {
      type: 'notesTodoEditor',
      modal: this.$currentUser.defaultEditorMode === 'modal',
      width: 600,
      input: { addWhat: null, editWhat: reminderData.reminderType, reminderData: reminderData }
    });
  }

  refresh() {
    this.localTodo = null;
    this.localListLoading = true;
    this.setTodos({});
    this.setNotes({});
    this.initialize(false);
  }

  async initialize(initialLoad:boolean) {
    if (initialLoad) CommonUtils.showLoading();
    const that = this;
    this.setHaveTodos(false);
    this.setHaveNotes(false);
    if (!this.$store.state.plans.currentData) {
      this.setResetOnly(false);
      await this.reloadPlans();
    }
    this.loadTodos().then(() => {
      that.setHaveTodos(true);
      that.loadNotes({}).then(() => {
        that.setHaveNotes(true);
        that.loadNotesStudents({}).then(() => {
          CommonUtils.hideLoading();
          this.localListLoading = false;
        })
      });
    });
  }

  created() {
    const that = this;
    this.initialize(true);
    this.$eventBus.$on('remindersRefresh', (reload: boolean) => {
      if (reload) {
        setTimeout(() => {
          that.refresh();
        }, 1000);
        // that.refresh();
      }
    });
    this.$eventBus.$emit('openDefaultSubPage');
  }

  destroyed() {
    this.$eventBus.$off('remindersRefresh');
  }
}
